<script>
    // -- IMPORTS

    import { getLocalizedText } from 'senselogic-gist';
    import AnimatedText from '../element/AnimatedText.svelte';
    import { languageTagStore } from '../../store/languageTagStore';
    import { textStore } from '../../store/textStore';
  import { getProcessedMultilineTranslatedText } from '$src/lib/base';

    // -- VARIABLES

    export let titleSlug = '';
    export let subtitleSlug = '';
    export let title = '';
    export let subtitle = '';

</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- CLASSES

    .page-heading
    {
        margin: 5rem 1.5rem;

        +media( desktop )
        {
            margin: 0;
        }
    }

    .page-heading-title,
    .page-heading-subtitle
    {
        width: 100%;

        line-height: 2.5rem;
        font-size: 2rem;
        font-weight: 400;
        letter-spacing: 0.16rem;
        text-align: left;
        text-transform: uppercase;

        +media( desktop )
        {
            line-height: 7.5rem;
            font-size: 6rem;
            font-weight: 250;
            letter-spacing: 0.48rem;
            text-align: center;
        }
    }

    .page-heading-subtitle
    {
        line-height: 2.25rem;
        font-size: 1.5rem;
        font-weight: 400;
        text-align: left;

        +media( desktop )
        {
            line-height: 2.25rem;
            font-size: 1.5rem;
            letter-spacing: 0.03rem;
            text-align: center;
        }
    }
</style>

<div class="page-heading">
    {#if titleSlug }
        <h1 class="page-heading-title color-light-gold">
            <AnimatedText text={ getLocalizedText( $textStore[ titleSlug ], $languageTagStore ) } />
        </h1>
    {/if}
    {#if title }
        <h1 class="page-heading-title color-light-gold">
            <AnimatedText text={ getLocalizedText( title, $languageTagStore ) } />
        </h1>
    {/if}
    {#if subtitleSlug }
        <h6 class="page-heading-subtitle color-light-grey">
            <AnimatedText text={ getProcessedMultilineTranslatedText( $textStore[ subtitleSlug ], $languageTagStore ) } />
        </h6>
    {/if}
    {#if subtitle }
        <h6 class="page-heading-subtitle color-light-grey">
            <AnimatedText text={ getProcessedMultilineTranslatedText( subtitle, $languageTagStore ) } />
        </h6>
    {/if}
</div>
