<script>
    // -- IMPORTS
    import { getJsonText, getLocalizedText } from 'senselogic-gist';
    import InputLocalizedForm from '../element/InputLocalizedForm.svelte';
    import Button from '../element/Button.svelte';
    import { fetchData } from '../../base';
    import { languageArrayStore } from '$lib/store/languageArrayStore';
    import { isLoadingStore } from '../../store/isLoadingStore';
    import  { toast } from '../../toast';
    import { createEventDispatcher, onMount } from 'svelte';

    // -- VARIABLES
    
    export let textData;
    export let isInsideModal = false;
    export let activeLanguageCode = 'en';
    export let isMultiline = true;

    let text = JSON.parse( textData.text );
    let error = null;
    let message = null;
    let form;
    let dispatch = createEventDispatcher();

    // -- FUNCTIONS

    function handleUpdateLocalizedInput(
        event
        )
    {
        textData.text = getJsonText( event.detail.text );
    }

    // ~~

    async function handleSubmit(
        event
        )
    {
        $isLoadingStore = true;

        try
        {
            error = null;
            message = null;
    
            let newText = textData.text;

            let response =
                await fetchData(
                    '/api/text/update/' + textData.slug,
                    {
                        method: 'POST',
                        body: getJsonText(
                            {
                                newText
                            }
                        ),
                        headers: { 'Content-Type': 'application/json' }
                    }
                    );
    
            if ( response.error )
            {
                console.error( response.error );
                toast.error( 'Something went wrong' );
            }
            else
            {
                toast.success( 'Text updated with success' );
            }
        }
        catch ( error )
        {
            console.log( error );
            toast.error( 'Something went wrong' );
        }
        finally
        {
            $isLoadingStore = false;
            dispatch( 'update-text' )
        }
    }

    // ~~

    function customSubmit(
        event
        )
    {
        if ( form )
        {
            let fakeSubmitEvent = new Event('submit', { bubbles: true, cancelable: true } );
            form.dispatchEvent( fakeSubmitEvent );
        }
    }

    // -- STATEMENTS

    onMount(
        () =>
        {
            window.addEventListener(
                'on-save-block',
                customSubmit
                );

            return () => {
                window.removeEventListener( 'on-save-block', customSubmit );
            }
        }
        );

</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';

    // -- ELEMENTS

    form
    {
        width: 50%;
        border: 1px solid lightGoldColor
        padding: 1.5rem 3rem;

        display: flex;
        flex-direction: column;
        gap: 1rem;

        background-color: darkGreyColor;
    }

    label
    {
        font-size: 1.25rem;
    }

    // -- CLASSES

    .is-inside-modal
    {
        width: 100%;
        border: none;
    }
</style>

<form
    bind:this={ form }
    id={ text.slug }
    class:is-inside-modal={ isInsideModal }
    on:submit|preventDefault={ handleSubmit }
>
    {#if error }
        <p class="card-form-error">{ error }</p>
    {/if}

    {#if message }
        <p class="card-form-message">{ message }</p>
    {/if}
    
    { #if !isInsideModal }
        <label for={ getLocalizedText( text, $languageArrayStore ) }>
            { textData.slug }
        </label>
    { /if }
    
    <InputLocalizedForm
        isMultiLine={ isMultiline }
        itemsString={ text }
        languageArray={ $languageArrayStore }
        name={ textData.slug }
        placeholder={ getLocalizedText( text ) }
        activeLanguageCode={ activeLanguageCode }
        on:update={ handleUpdateLocalizedInput }
    />

    { #if !isInsideModal }
        <Button type="submit" loading={ $isLoadingStore }>
            SUBMIT
        </Button>
    { /if }
</form>
