<script>
    // -- IMPORTS
    
	import EditManagerPage from './../../../../../ADMIN/src/lib/page/manager/EditManagerPage.svelte';
    import Modal from "../../../../../ADMIN/src/lib/component/element/Modal.svelte";
    import { getLocalizedText } from "senselogic-gist";
    import AdminEditButton from "../element/AdminEditButton.svelte";
    import AnimatedContainer from "../element/AnimatedContainer.svelte";
    import ContactButtons from "../element/ContactButtons.svelte";
    import Media from "../element/Media.svelte";
    import { enabledAdminView } from "$src/lib/store/adminStore";
    import { languageTagStore } from "$src/lib/store/languageTagStore";
    import { fetchData, getProcessedMultilineTranslatedText } from "$src/lib/base";
    import { onMount } from "svelte";

    // -- VARIABLES

    export let id = '';
    export let name = '';
    export let imageSide = 'right';
    export let mediaPath = '';
    export let mediaPathArray = [];
    export let position = '';
    export let biography = '';
    export let emailAddress = '';
    export let linkedinLink = '';
    export let instagramLink = '';
    export let number = 0;

    let isEditManagerModalOpen = false;

    // -- FUNCTIONS

    function handleOpenManagerModal(
        )
    {
        isEditManagerModalOpen = true;
    }

    // ~~

    async function loadData(
        )
    {
        let managerData =
            await fetchData(
                '/api/manager/get-by-id',
                {
                    method: 'POST',
                    body: JSON.stringify(
                        {
                            id
                        },
                    ),
                    headers: { 'Content-Type': 'application/json' }
                }
            );

        let manager = managerData.managerMember;
        
        name = manager.name;
        imageSide = manager.imageSide;
        mediaPathArray = manager.mediaPathArray;
        position = manager.position;
        biography = manager.biography;
        emailAddress = manager.emailAddress;
        linkedinLink = manager.linkedinLink;
        instagramLink = manager.instagramLink;
    }

    // ~~

    function handleUpdateManager(
        )
    {
        loadData();
        isEditManagerModalOpen = false;
    }
</script>

<style lang="stylus">
    // -- IMPORTS

    @import '../../../constant.styl';
    @import '../../../mixin.styl';

    // -- CLASSES

    .manager-card
    {
        position: relative;
        width: 100%;

        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: flex-start;
        align-self: stretch;

        +media( desktop )
        {
            flex-direction: row;
            gap: 5rem;
        }
    }

    .manager-card.reverse
    {
        +media( desktop )
        {
            flex-direction: row-reverse;
        }
    }

    .manager-card-content
    {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: flex-start;
        align-self: stretch;

        +media( desktop )
        {
            width: 100%;

            flex: 1 0 0;
            gap: 2rem;
            justify-content: center;
        }
    }

    .manager-card-content-text
    {
        line-height: 2rem;
        font-size: 1.25rem;
        color: #BFC6CA;
    }

    .manager-card-heading-title
    {
        line-height: 3rem;
        font-size: 2rem;
        font-weight: 600;
        color: whiteColor;
    }

    .manager-card-image-container
    {
        height: auto;
        height: 24rem;
        max-width: 32rem;
        aspect-ratio: 1;

        object-fit: cover;
        object-position: center;

        +media( desktop )
        {
            position: sticky;
            top: 8rem;

            height: 30rem;
            aspect-ratio: 4 / 3;
        }
    }

    :global( .manager-card-image )
    {
        height: 100%;
        width: 100%;

        filter: grayscale( 100% );
    }

    .description-text
    {
        position: relative;

        display: -webkit-box;
        -webkit-box-orient: vertical;

        transition: all .3s;
    }
</style>

<Modal
    bind:showModal={ isEditManagerModalOpen }
    modalConfirmButtonLabel="Save"
    modalType=""
    hasFooterButtons={ false }
    modalTitle="Edit Manager"
>
    <EditManagerPage
        managerId={ id }
        on:updateSuccess={ handleUpdateManager }
    />
</Modal>

{ #key [ name, imageSide, mediaPathArray, position, biography, emailAddress, linkedinLink, instagramLink ] }
    <AnimatedContainer 
        style="{ imageSide === 'right' ? 'align-self: flex-end' : 'align-self: flex-start' }; width: 100%;"
    >
        <div class="manager-card { imageSide === 'right' ? 'reverse' : '' }" class:is-admin-enabled={ $enabledAdminView }>
            <AdminEditButton
                handleAdminButton={ handleOpenManagerModal }
                title={ name }
            />

            <div class="manager-card-image-container">
                <Media
                    mediaId={ id }
                    mediaClass="manager-card-image"
                    mediaPath={ mediaPath || mediaPathArray }
                />
            </div>

            <div class="manager-card-content">
                <div>
                    <p class="manager-card-heading-title">{ name }</p>
                    <p class="manager-card-content-text">{ @html getProcessedMultilineTranslatedText( position, $languageTagStore ) }</p>
                </div>

                <div id={`biography-${ id }`} class="description-text">
                    {@html getProcessedMultilineTranslatedText( biography || '', $languageTagStore )}
                </div>

                <ContactButtons { emailAddress } { linkedinLink } { instagramLink } />
            </div>
        </div>
    </AnimatedContainer>
{/key}
